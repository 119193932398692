import Header from "../../components/Header";
import styles from "./style.module.css";
import Testimonials from "../../components/Testimonials";
import NextSteps from "../../components/NextSteps";
import Heading from "../../components/Heading";
import Footer from "../../components/Footer";
import Product from "./components/Product";
import ProductGuidance from "../../components/ProductGuidance";
import Head from "../../components/Head";
import useGoogleAnalyticsEvent from "../../hooks/UseGoogleAnalyticsEvent";
import {t} from "../../intl/Intl";
import {
    LANGUAGE,
    PD_PEPTYS_21,
    PD_PEPTYS_22,
    PD_PEPTYS_51,
    PD_PEPTYS_52,
    PD_PEPTYS_REGAIN,
    SALES_CONTACTS
} from "../../enviroment";
import {useMemo} from "react";
import ContactCard, {ContactCardProps} from "../ContactPage/components/ContactCard";

const BuyPage = () => {

    const logEventMapClick = useGoogleAnalyticsEvent('map_click', 'kde_na_peptys');

    const mappedContactCards = useMemo(() => {
        return SALES_CONTACTS.map((contact: ContactCardProps, index) => {
            return <ContactCard key={index} {...contact} />
        });
    }, []);

    return (
        <>
            <Header backgroundColor={"light"}/>
            <div className={"container"}>
                <div className={styles.container}>

                    {
                        LANGUAGE === 'sk' ? <>
                            <Heading heading={t('applicationHeading')} lead={t('applicationLead')}/>
                            <div className={styles.mapContainer}>
                                <iframe title={t('mapString')}
                                        src="https://www.google.com/maps/d/embed?mid=1pKhDK9aAdKSLuCGl0p8rrBSkJ02yPS0&amp;ehbc=2E312F&amp;noprof=1"
                                        width="100%" height="540" className={styles.map}
                                        onClick={logEventMapClick}></iframe>
                            </div>
                        </> : <>
                            <Heading heading={t('applicationHeading')} lead={"Spojte se s naším obchodním zástupcem, který je vám připraven poskytnout aktuální informace o místech, kde je možné aplikovat PEPTYS."}/>
                            <div className={styles.contactContainer}>
                                {mappedContactCards}
                            </div>
                        </>
                    }

                    <div className={styles.productsContainer}>
                        <Product imgSrc={'/images/products/peptys21-800x800.webp'} imgWidth={236} imgHeight={113}
                                 title={"PEPTYS 21/1ml"} price={PD_PEPTYS_21.price} link={PD_PEPTYS_21.link}/>
                        <Product imgSrc={'/images/products/peptys22-800x800.webp'} imgWidth={236} imgHeight={114}
                                 title={"PEPTYS 22/2ml"} price={PD_PEPTYS_22.price} link={PD_PEPTYS_22.link}/>
                        <Product imgSrc={'/images/products/peptys51-800x800.webp'} imgWidth={236} imgHeight={112}
                                 title={"PEPTYS 51/1ml"} price={PD_PEPTYS_51.price} link={PD_PEPTYS_51.link}/>
                        <Product imgSrc={'/images/products/peptys52-800x800.webp'} imgWidth={236} imgHeight={112}
                                 title={"PEPTYS 52/2ml"} price={PD_PEPTYS_52.price} link={PD_PEPTYS_52.link}/>
                        <Product imgSrc={'/images/products/peptys110-800x800.webp'} imgWidth={236} imgHeight={144}
                                 title={"PEPTYS 110/10ml Regain"} price={PD_PEPTYS_REGAIN.price}
                                 link={PD_PEPTYS_REGAIN.link}/>
                    </div>

                    <Heading heading={t('productGuideHeading')}
                             lead={t('productGuideLead')}/>
                    <ProductGuidance/>

                    <Heading heading={t('testimonialsHeading')}
                             lead={t('testimonialsLead')}/>
                    <Testimonials/>

                </div>
            </div>
            <NextSteps marginTop={true}/>
            <Footer/>
            <Head
                title={t('applicationPageMetaTitle')}
                description={t('applicationPageMetaDescription')}
            />
        </>
    )
}

export default BuyPage