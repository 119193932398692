import ReactGA from "react-ga4";
import {useCallback, useState} from "react";

const useGoogleAnalyticsEvent = (action: string, category: string, lead?: string) => {
    const [eventLogged, setEventLogged] = useState(false);

    const logEvent = useCallback(() => {
        if (!eventLogged) {
            ReactGA.event({action, category, label: lead});
            setEventLogged(true);
        }
    }, [eventLogged, action, category, lead]);

    return logEvent;
}

export default useGoogleAnalyticsEvent;